/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FunctionComponent } from 'react';
import Header from '../komponents/Header/Header';
import Footer from '../komponents/Footer/Footer';
import FlowChooseProvider from '../komponents/Flow/FlowChooseProvider';

const EligeProveedor: FunctionComponent = () => (
  <>
    <Header />

    <FlowChooseProvider />

    <Footer />
  </>
);

export default EligeProveedor;

export const Head = () => (
  <>
    <meta charSet="utf-8" />
    <meta name="description" content="Elige Terminal Punto de venta" />
    <title>Elige Terminal - PuntoTerminal.mx</title>
  </>
);
