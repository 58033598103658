// extracted by mini-css-extract-plugin
export var bigPad = "FlowChooseProvider-module--bigPad--91b79";
export var cardClass = "FlowChooseProvider-module--cardClass--20aa7";
export var centerText = "FlowChooseProvider-module--centerText--a8e65";
export var cheapTableRow = "FlowChooseProvider-module--cheapTableRow--787db";
export var expensiveTableRow = "FlowChooseProvider-module--expensiveTableRow--73259";
export var linkColor = "FlowChooseProvider-module--linkColor--3172c";
export var pad = "FlowChooseProvider-module--pad--d8739";
export var providerImg = "FlowChooseProvider-module--providerImg--5f6c8";
export var rowTextColor = "FlowChooseProvider-module--rowTextColor--3e4ee";
export var smallPad = "FlowChooseProvider-module--smallPad--ffb65";