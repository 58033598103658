// extracted by mini-css-extract-plugin
export var flowBottomDiv = "FlowBottom-module--flowBottomDiv--13860";
export var helpText = "FlowBottom-module--helpText--2442a";
export var miniPad = "FlowBottom-module--miniPad--e8990";
export var pad = "FlowBottom-module--pad--46813";
export var priceGuarantee = "FlowBottom-module--priceGuarantee--d959b";
export var smallPad = "FlowBottom-module--smallPad--47a65";
export var textGroup = "FlowBottom-module--textGroup--8e7a6";
export var textHeader = "FlowBottom-module--textHeader--2074c";
export var textParagraph = "FlowBottom-module--textParagraph--437ea";
export var topText = "FlowBottom-module--topText--82149";