import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
  pad,
  progressBar,
  progressBarActive,
  progressTextActive,
  progressTextMiddleInactive,
  progressTextRightInactive,
  progressTextMiddleActive,
  progressTextRightActive,
  progressBarDiv,
  progressBarCont,
} from './ProgressBar.module.scss';
import circleGrey from '../../../static/images/png/circle-dot-grey.png';
import circleBlue from '../../../static/images/png/circle-dot-blue.png';
import useLanguageStore from '../LanguageStore/LanguageStore';
import useFlowProgressStore from '../FlowStore/FlowProgressStore';

type LanguageContent = {
  preferences: string;
  select: string;
  send: string;
};

const content: { [name: string]: LanguageContent } = {};
content.English = {
  preferences: 'Preferences',
  select: 'Select',
  send: 'Send',
};
content.Español = {
  preferences: 'Preferencias',
  select: 'Eligir',
  send: 'Enviar',
};

const ProgressBar = () => {
  // Import states
  const flowProgress: number = useFlowProgressStore(
    (state) => state.flowProgress
  );

  const language = useLanguageStore((state) => state.language);
  let choosenLanguage: LanguageContent;
  language === 'Español'
    ? (choosenLanguage = content.Español)
    : (choosenLanguage = content.English);

  let progressMiddleCSS = progressTextMiddleInactive;
  let progressRightCSS = progressTextRightInactive;
  let progressBarLeftCSS = progressBar;
  let progressBarRightCSS = progressBar;
  let circleMiddle = circleGrey;
  let circleRight = circleGrey;
  if (flowProgress === 2) {
    progressMiddleCSS = progressTextMiddleActive;
    progressBarLeftCSS = progressBarActive;
    circleMiddle = circleBlue;
  } else if (flowProgress >= 3) {
    progressMiddleCSS = progressTextMiddleActive;
    progressRightCSS = progressTextRightActive;
    progressBarLeftCSS = progressBarActive;
    progressBarRightCSS = progressBarActive;
    circleMiddle = circleBlue;
    circleRight = circleBlue;
  }

  return (
    <div className={progressBarDiv}>
      <div className={pad} />
      <div className={progressBarCont}>
        <Container>
          <div>
            <Row>
              <Col>
                <div className={progressTextActive}>
                  {choosenLanguage.preferences}
                </div>
              </Col>
              <Col>
                <div className={progressMiddleCSS}>
                  {choosenLanguage.select}
                </div>
              </Col>
              <Col>
                <div className={progressRightCSS}>{choosenLanguage.send}</div>
              </Col>
            </Row>
            <div className="d-flex justify-content-between align-items-center position-relative ">
              <img src={circleBlue} />
              <div className={progressBarLeftCSS} />

              <img src={circleMiddle} />
              <div className={progressBarRightCSS} />

              <img src={circleRight} />
            </div>
          </div>
        </Container>
      </div>
      <div className={pad} />
    </div>
  );
};

export default ProgressBar;
